/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { useContext, useEffect, useRef, useState } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Glide from "@glidejs/glide";
import { DocumentContext } from "~context/DocumentContext";
import AppearOnScroll from "~components/AppearOnScroll";
import CursorHoverable from "~components/CursorHoverable";
import Footer from "~components/Footer";
import Layout from "~components/Layout";
import ScreenHeight from "~components/ScreenHeight";
import SEO from "~components/SEO";
import { useKeyPress } from "~utils/hooks";

const ExhibitionPage = ({ data, location }) => {
  const { device, windowHeight, windowWidth } = useContext(DocumentContext);

  const glideRef = useRef();

  const [galleryActive, setGalleryActive] = useState(false);
  const [galleryIndex, setGalleryIndex] = useState(null);
  const [glide, setGlide] = useState(null);

  //

  const { frontmatter } = data.markdownRemark;

  const gallery = [];
  const masonry = [[], [], []];

  if (frontmatter?.gallery?.[0]) {
    frontmatter.gallery.forEach((galleryItem, galleryItemIndex) => {
      const imageRef = useRef();

      galleryItem.ref = imageRef;

      gallery.push(galleryItem);

      masonry[galleryItemIndex % 3].push(galleryItem);
    });
  }

  //

  const escKeyPressed = useKeyPress(`Escape`);

  useEffect(() => {
    setGalleryActive(false);
  }, [escKeyPressed]);

  useEffect(() => {
    if (!glideRef?.current || !windowWidth || glide) {
      return;
    }

    const glideCarousel = new Glide(`#modal-glide`, {
      autoplay: false,
      gap: 0,
      perView: 1,
      type: `carousel`
    });

    glideCarousel.on(`run`, () => {
      setGalleryIndex(glideCarousel.index);
    });

    glideCarousel.mount();

    setGlide(glideCarousel);
  }, [glideRef, windowWidth]);

  //

  return (
    <>
      <SEO
        customTitle={frontmatter.title}
        customDescription={frontmatter.seoDescription}
        customKeywords={frontmatter.seoKeywords}
        path={location.pathname}
      />

      <ScreenHeight
        className={`exhibition-page__overlay ${
          galleryActive ? `active` : ``
        } transition-opacity w-full fixed top-0 right-0 bottom-0 left-0 z-10 flex items-center justify-center bg-black text-white`}
      >
        <div className="absolute top-0 right-0 left-0 z-10">
          <CursorHoverable>
            <button
              type="button"
              className="relative block hover-underline mx-auto mt-20 b1 italic text-center"
              onClick={() => setGalleryActive(false)}
            >
              Back to gallery
            </button>
          </CursorHoverable>
        </div>

        <div className="h-full grid">
          {device && device === `desktop` && (
            <div className="h-full grid-end-4 relative flex items-center justify-start text-left">
              <CursorHoverable>
                <button
                  type="button"
                  className="block hover-underline"
                  onClick={() => {
                    if (!glide) {
                      return;
                    }

                    glide.go(`<`);
                  }}
                >
                  <span className="pointer-events-none f4">Previous</span>
                </button>
              </CursorHoverable>
            </div>
          )}

          <section className="h-full relative grid-end-16 sm:grid-end-24 grid-start-5 sm:grid-start-1 flex items-center justify-center overflow-hidden">
            <div className="w-full relative">
              <div id="modal-glide" ref={glideRef} className="glide">
                <div className="glide__track" data-glide-el="track">
                  <ul className="glide__slides relative flex items-center justify-center">
                    {gallery?.[0] &&
                      gallery.map((galleryItem, galleryItemIndex) => {
                        const galleryKey = `gallery-overlay-${galleryItemIndex}`;

                        const visible =
                          galleryActive &&
                          (galleryItemIndex === galleryIndex ||
                            galleryItemIndex + 1 === galleryIndex ||
                            galleryItemIndex - 1 === galleryIndex);

                        return (
                          <li
                            key={galleryKey}
                            className="exhibition-page__carousel__item h-full relative flex items-center justify-center"
                          >
                            <figure className="w-full h-full object-contain pl-v1 pr-v1">
                              {visible && (
                                <Img
                                  className="w-full h-full relative block object-contain"
                                  fluid={
                                    galleryItem.image.childImageSharp.fluid
                                  }
                                  alt={galleryKey}
                                />
                              )}
                            </figure>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
          </section>

          {device && device === `desktop` && (
            <div className="h-full grid-end-4 relative flex items-center justify-end text-right">
              <CursorHoverable>
                <button
                  type="button"
                  className="block hover-underline"
                  onClick={() => {
                    if (!glide) {
                      return;
                    }

                    glide.go(`>`);
                  }}
                >
                  <span className="pointer-events-none f4">Next</span>
                </button>
              </CursorHoverable>
            </div>
          )}

          {device && device !== `desktop` && (
            <div className="grid-end-24 flex items-center justify-between">
              <button
                type="button"
                className="block hover-underline"
                onClick={() => {
                  if (!glide) {
                    return;
                  }

                  glide.go(`<`);
                }}
              >
                <span className="pointer-events-none f4">Previous</span>
              </button>

              <button
                type="button"
                className="block hover-underline"
                onClick={() => {
                  if (!glide) {
                    return;
                  }

                  glide.go(`>`);
                }}
              >
                <span className="pointer-events-none f4">Next</span>
              </button>
            </div>
          )}
        </div>
      </ScreenHeight>

      <Layout className="exhibition-page w-full relative block bg-black">
        <section className="w-full relative block mt-v10 xs:mt-24">
          <div className="grid">
            <article className="grid-end-20 sm:grid-end-24 text-white">
              <h3 className="animation-appear-down-slow animation-delay-2 b1">
                Gallery
              </h3>

              <h1 className="animation-appear-down-slow animation-delay-3 mt-v1 xs:mt-4 mb-v1 xs:mb-2 f3">
                {frontmatter.title}
              </h1>

              <h3
                className={`animation-appear-down-slow animation-delay-4 ${
                  device === `desktop` ? `f5` : `b1`
                } italic`}
              >
                {frontmatter.date}
              </h3>

              <p
                className={`animation-appear-down-slow animation-delay-5 mt-v3 xs:mt-12 mb-v8 xs:mb-16 ${
                  device === `desktop` ? `f5` : `b1`
                }`}
              >
                {frontmatter.description}
              </p>
            </article>
          </div>

          <div className="animation-appear-down-slow animation-delay-5">
            {masonry?.[0]?.[0] && (
              <ul className="grid">
                {masonry.map((masonryColumn, masonryColumnIndex) => {
                  const masonryKey = `masonry-column-${masonryColumnIndex}`;

                  return (
                    <li key={masonryKey} className="grid-end-8 sm:grid-end-24">
                      {masonryColumn?.[0] &&
                        masonryColumn.map((galleryItem, galleryItemIndex) => {
                          const galleryKey = `masonry-${masonryColumnIndex}-${galleryItemIndex}`;

                          const visible =
                            galleryItem?.ref?.current &&
                            galleryItem.ref.current.getBoundingClientRect()
                              .top < windowHeight;

                          //

                          const adjustedIndex =
                            galleryItemIndex * 3 + masonryColumnIndex;

                          return (
                            <div
                              key={galleryKey}
                              ref={galleryItem.ref}
                              className="w-full relative block xs:mb-6 pb-v3 pr-v05 xs:pr-0 pl-v05 xs:pl-0"
                            >
                              <CursorHoverable>
                                <button
                                  type="button"
                                  className="w-full relative block"
                                  onClick={() => {
                                    if (!glide) {
                                      return;
                                    }

                                    glide.go(`=${adjustedIndex}`);

                                    setGalleryActive(true);
                                  }}
                                >
                                  <AppearOnScroll>
                                    {(visible && (
                                      <Img
                                        className="w-full relative block"
                                        fluid={
                                          galleryItem.image.childImageSharp
                                            .fluid
                                        }
                                        alt={galleryKey}
                                      />
                                    )) || <></>}
                                  </AppearOnScroll>
                                </button>
                              </CursorHoverable>
                            </div>
                          );
                        })}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </section>
      </Layout>

      <Footer />
    </>
  );
};

export default ExhibitionPage;

export const query = graphql`
  query ExhibitionPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        date
        description
        gallery {
          image {
            childImageSharp {
              fluid(maxWidth: 1440, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
            publicURL
          }
        }
        seoDescription
        seoKeywords
      }
    }
  }
`;
